























































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Organization from '../../../../../store/models/Organization'
import CreditLine from '../../../../../store/models/CreditLine'

export default Vue.extend({
  components: {},

  data() {
    return {
      loading: false,
      activeDeadlinesTab: 'mtn',
      showFaillureNotification: false,
      columns: [
        { label: 'N°', field: '_index' },
        { label: 'Échéance', field: 'date' },
        { label: 'Capital', field: 'capital' },
        { label: 'Interêts', field: 'interest' },
        { label: 'Mensualité', field: 'monthlyAmount' }
      ]
    }
  },

  computed: {
    ...mapGetters('entities/credit_line_provisions', ['newProvision']),
    ...mapGetters('product', ['getCurrentProduct']),

    totalAmount() {
      return this.newProvision.mtnAmount + this.newProvision.moovAmount
    },

    sfdName(): string {
      const sfd = Organization.find(this.newProvision.organizationId)
      return sfd ? sfd.name : ''
    },

    deadlines(this: any) {
      return this.activeDeadlinesTab === 'mtn'
        ? this.newProvision.mtnDeadlines
        : this.newProvision.moovDeadlines
    },

    totalCapital(this: any): number {
      return this.deadlines.reduce(
        (total: number, item: any) => total + item.capital,
        0
      )
    },

    totalInterest(this: any): number {
      return this.deadlines.reduce(
        (total: number, item: any) => total + item.interest,
        0
      )
    },

    monthlyAmountTotal(this: any): number {
      return this.deadlines.reduce(
        (total: number, item: any) => total + item.monthlyAmount,
        0
      )
    },

    interestCreditRate(this: any) {
      return this.getCurrentProduct.reimbursementDetails.sfdInterestRate
    },

    newProvisionEffectDateFormat(this: any) {
      return moment(this.newProvision.effectDate).format('DD/MM/YYYY')
    }
  },

  methods: {
    onSubmit(this: any) {
      this.loading = true

      CreditLine.api()
        .create({
          ...this.newProvision,
          deadlines: [
            ...this.newProvision.mtnDeadlines,
            ...this.newProvision.moovDeadlines
          ]
        })
        .then(() => {
          this.loading = false
          this.$notify.success('La ligne de crédit a été crée avec succès !')
          setTimeout(() => {
            this.$router.push({ name: 'creditlines.index' })
          })
        })
        .catch((error: any) => {
          this.loading = false
          if (error.response && error.response.status === 424) {
            this.showFaillureNotification = true
          } else if (error.response && error.response.status === 400) {
            error.response.data.message.forEach((message: any) => {
              if (['name', 'reference'].includes(message.property)) {
                this.$router.push({
                  name: 'creditlines.create',
                  params: { error: message }
                })
              }
            })
          }
        })
    }
  }
})
